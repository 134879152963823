import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/register/:registerToken',
        name: 'register',
        component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
        props: {
            name: 'Register',
            icon: ['fas', 'check-circle'],
            api: '/users',
        },
    },
    {
        path: '/',
        redirect: '/dashboard',
        component: () => import(/* webpackChunkName: "framework" */ '../views/FrameWork.vue'),
        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
                props: {
                    name: 'Dashboard',
                    icon: ['fas', 'tachometer-alt'],
                    api: '/dashboard',
                },
            },
            {
                path: 'products',
                component: () => import(/* webpackChunkName: "crud" */ '../views/Crud.vue'),
                props: {
                    name: 'Product',
                    icon: ['fas', 'bars'],
                    api: '/products',
                    filterPlaceholder: 'Enter a number of search terms separated by a space',
                },
            },
            {
                path: 'products/:id',
                name: 'products',
                component: () => import(/* webpackChunkName: "product" */ '../views/Product.vue'),
                props: {
                    name: 'Product',
                    icon: ['fas', 'toolbox'],
                    api: '/products',
                },
            },
            {
                path: 'inventory',
                component: () => import(/* webpackChunkName: "crud" */ '../views/Crud.vue'),
                props: {
                    name: 'Inventory Management',
                    icon: ['fas', 'cart-flatbed'],
                    api: '/products/inventory',
                    filterPlaceholder: 'Enter a number of search terms separated by a space',
                },
            },
            {
                path: 'inventory/:id',
                name: 'inventory',
                component: () => import(/* webpackChunkName: "inventorymovements" */ '../views/InventoryMovements.vue'),
                props: {
                    name: 'Inventory',
                    icon: ['fas', 'cart-flatbed'],
                    api: '/inventory_movements',
                },
            },
            {
                path: 'products/more/inventory_forecast',
                name: 'inventory_forecast',
                component: () => import(/* webpackChunkName: "inventoryforecast" */ '../views/InventoryForecast'),
                props: {
                    name: 'Inventory Forecast',
                    icon: ['fas', 'calendar'],
                    api: '/inventory_forecast',
                },
            },
            {
                path: 'products/more/categories',
                component: () => import(/* webpackChunkName: "crud" */ '../views/Crud.vue'),
                props: {
                    name: 'Product Category',
                    icon: ['fas', 'tasks'],
                    api: '/product_categories',
                    filterPlaceholder: 'Filter',
                },
            },
            {
                path: 'products/more/units',
                component: () => import(/* webpackChunkName: "crud" */ '../views/Crud.vue'),
                props: {
                    name: 'Unit',
                    icon: ['fas', 'pencil-ruler'],
                    api: '/units',
                    filterPlaceholder: 'Filter',
                },
            },
            {
                path: 'projects',
                component: () => import(/* webpackChunkName: "crud" */ '../views/Crud.vue'),
                props: {
                    name: 'Project',
                    icon: ['fas', 'bars'],
                    api: '/projects',
                    filterPlaceholder: 'Enter a number of search terms separated by a space',
                },
            },
            {
                path: 'projects/:id',
                name: 'projects',
                component: () => import(/* webpackChunkName: "project" */ '../views/Project.vue'),
                props: {
                    name: 'Project',
                    icon: ['fas', 'calculator'],
                    api: '/projects',
                },
            },
            {
                path: 'estimates',
                component: () => import(/* webpackChunkName: "crud" */ '../views/Crud.vue'),
                props: {
                    name: 'Estimate',
                    icon: ['fas', 'table'],
                    api: '/estimates',
                    filterPlaceholder: 'Enter a number of search terms separated by a space',
                },
            },
            {
                path: 'estimates/:id',
                name: 'estimates',
                component: () => import(/* webpackChunkName: "document" */ '../views/Document.vue'),
                props: {
                    name: 'Estimate',
                    document: 'Estimate',
                    icon: ['fas', 'calculator'],
                    type: 'estimate',
                    api: '/estimates',
                },
            },
            {
                path: 'quotes',
                component: () => import(/* webpackChunkName: "crud" */ '../views/Crud.vue'),
                props: {
                    name: 'Quote',
                    icon: ['fas', 'table'],
                    api: '/quotes',
                    filterPlaceholder: 'Enter a number of search terms separated by a space',
                },
            },
            {
                path: 'quotes/:id',
                name: 'quotes',
                component: () => import(/* webpackChunkName: "document" */ '../views/Document.vue'),
                props: {
                    name: 'Quote',
                    document: 'Quote',
                    icon: ['fas', 'calculator'],
                    type: 'quote',
                    api: '/quotes',
                },
            },
            {
                path: 'orders',
                component: () => import(/* webpackChunkName: "crud" */ '../views/Crud.vue'),
                props: {
                    name: 'Order',
                    icon: ['fas', 'truck'],
                    api: '/orders',
                    filterPlaceholder: 'Enter a number of search terms separated by a space',
                },
            },
            {
                path: 'orders/:id',
                name: 'orders',
                component: () => import(/* webpackChunkName: "document" */ '../views/Document.vue'),
                props: {
                    name: 'Order',
                    document: 'Order',
                    icon: ['fas', 'truck'],
                    type: 'order',
                    api: '/orders',
                },
            },
            {
                path: 'managed_orders/:id',
                name: 'managed_orders',
                component: () => import(/* webpackChunkName: "document" */ '../views/Document.vue'),
                props: {
                    name: 'Managed Order',
                    document: 'Order',
                    icon: ['fas', 'calculator'],
                    type: 'managed_order',
                    api: '/managed_orders',
                },
            },
            {
                path: 'pick_up_receipts/:id',
                name: 'pick_up_receipts',
                component: () => import(/* webpackChunkName: "pickupreceipt" */ '../views/PickUpReceipt.vue'),
                props: {
                    name: 'Pick Up Receipt',
                    document: 'Order',
                    icon: ['fas', 'receipt'],
                    api: '/pick_up_receipts',
                },
            },
            {
                path: 'work_plans',
                name: 'work_plan_list',
                component: () => import(/* webpackChunkName: "workplanlist" */ '../views/WorkPlanList.vue'),
                props: {
                    name: 'Work Plan',
                    icon: ['fas', 'calendar'],
                    api: '/work_plans',
                },
            },
            {
                path: 'work_plans/:id',
                name: 'work_plans',
                component: () => import(/* webpackChunkName: "workplan" */ '../views/WorkPlan.vue'),
                props: {
                },
            },
            {
                path: 'projects/more/contacts',
                component: () => import(/* webpackChunkName: "crud" */ '../views/Crud.vue'),
                props: {
                    name: 'Contact',
                    icon: ['fas', 'address-book'],
                    api: '/contacts',
                    filterPlaceholder: 'Filter',
                },
            },
            {
                path: 'projects/more/financial_years',
                component: () => import(/* webpackChunkName: "crud" */ '../views/Crud.vue'),
                props: {
                    name: 'Financial Year',
                    icon: ['fas', 'calendar-alt'],
                    api: '/financial_years',
                    filterPlaceholder: 'Filter',
                },
            },
            {
                path: 'projects/more/statuses',
                component: () => import(/* webpackChunkName: "crud" */ '../views/Crud.vue'),
                props: {
                    name: 'Project Status',
                    icon: ['fas', 'thumbs-up'],
                    api: '/project_status',
                    filterPlaceholder: 'Filter',
                },
            },
            {
                path: 'reporting',
                component: () => import(/* webpackChunkName: "reporting" */ '../views/Reporting.vue'),
                props: {
                    name: 'Reporting',
                    icon: ['fas', 'print'],
                    api: '/reporting',
                },
            },
            {
                path: 'sketchup/areas',
                component: () => import(/* webpackChunkName: "areas" */ '../views/sketchup/Areas.vue'),
                props: {
                    name: 'Area',
                    icon: ['fas', 'fa-pen-ruler'],
                    api: '/sketchup',
                },
            },
            {
                path: 'sketchup/assembly',
                component: () => import(/* webpackChunkName: "assembly" */ '../views/sketchup/Assembly.vue'),
                props: {
                    name: 'Assembly',
                    icon: ['fas', 'fa-pen-ruler'],
                    api: '/sketchup',
                },
            },
            {
                path: 'sketchup/components',
                component: () => import(/* webpackChunkName: "components" */ '../views/sketchup/Components.vue'),
                props: {
                    name: 'Component',
                    icon: ['fas', 'fa-pen-ruler'],
                    api: '/sketchup',
                },
            },
            {
                path: 'sketchup/punchplates',
                component: () => import(/* webpackChunkName: "punchplates" */ '../views/sketchup/PunchPlates.vue'),
                props: {
                    name: 'Punch Plate',
                    icon: ['fas', 'fa-pen-ruler'],
                    api: '/sketchup',
                },
            },
            {
                path: 'sketchup/punchplates2',
                component: () => import(/* webpackChunkName: "punchplates2" */ '../views/sketchup/PunchPlates2.vue'),
                props: {
                    name: 'Punch Plate',
                    icon: ['fas', 'fa-pen-ruler'],
                    api: '/sketchup',
                },
            },
            {
                path: 'sketchup/cuttinglist',
                component: () => import(/* webpackChunkName: "cuttinglist" */ '../views/sketchup/CuttingList.vue'),
                props: {
                    name: 'Cutting List',
                    icon: ['fas', 'fa-pen-ruler'],
                    api: '/sketchup',
                },
            },
            {
                path: 'sketchup/rfem',
                component: () => import(/* webpackChunkName: "RFEM" */ '../views/sketchup/RFEM.vue'),
                props: {
                    name: 'RFEM',
                    icon: ['fas', 'fa-pen-ruler'],
                    api: '/sketchup',
                },
            },
            {
                path: 'admin/settings',
                component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
                props: {
                    name: 'Settings',
                    icon: ['fas', 'cogs'],
                    api: '/variables'
                },
            },
            {
                path: 'admin/users',
                name: 'users',
                component: () => import(/* webpackChunkName: "crud" */ '../views/Crud.vue'),
                props: {
                    name: 'User',
                    icon: ['fas', 'bars'],
                    api: '/users',
                    filterPlaceholder: 'Filter',
                },
            },
            {
                path: 'admin/more/roles',
                component: () => import(/* webpackChunkName: "crud" */ '../views/Crud.vue'),
                props: {
                    name: 'Role',
                    icon: ['fas', 'users-cog'],
                    api: '/roles',
                    filterPlaceholder: 'Filter',
                },
            },
            {
                path: 'admin/more/permissions',
                component: () => import(/* webpackChunkName: "crud" */ '../views/Crud.vue'),
                props: {
                    name: 'Permission',
                    icon: ['fas', 'user-cog'],
                    api: '/permissions',
                    filterPlaceholder: 'Filter',
                },
            },
        ]
    },
    {
        path: "*",
        redirect: "dashboard",
    }
]

const router = new VueRouter({
    routes
})

export default router
